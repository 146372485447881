var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2",attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"mt-2 mt-md-0",attrs:{"md":"2"}},[_c('b-button',{staticClass:"mr-2 btn-block",attrs:{"disabled":!(_vm.isEventsCreated.length && !!_vm.selectedEventId),"variant":"primary"},on:{"click":function($event){return _vm.showAddSessionFormAndHideEditForm()}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add New Session")])])],1)],1),_c('b-table',{ref:"table",staticClass:"position-relative",attrs:{"no-provider-sorting":true,"items":_vm.getSelectedSessionList,"show-empty":"","empty-text":"There is no Sessions for this Event!","fields":_vm.sessionsTableColumns,"aria-busy":"true","responsive":"","primary-key":"id"},scopedSlots:_vm._u([{key:"cell(required_vaccination)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"type":"checkbox","value":1,"unchecked-value":0,"disabled":""},model:{value:(item.required_vaccination),callback:function ($$v) {_vm.$set(item, "required_vaccination", $$v)},expression:"item.required_vaccination"}})]}},{key:"cell(target_gender)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.session_target_gender))])]}},{key:"cell(session_status)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"type":"checkbox","value":"active","unchecked-value":"inactive"},on:{"change":function($event){return _vm.updateSessionStatus(item.id)}},model:{value:(item.session_status),callback:function ($$v) {_vm.$set(item, "session_status", $$v)},expression:"item.session_status"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showEditSessionFormAndHideAddForm(item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(" Edit ")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.duplicateSession(item)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_c('span',[_vm._v(" Duplicate ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }